import React from 'react'
import BlockRichText from '../block-rich-text';

type Props = {}

function Intro({ data }) {
  if (!data) {
    return null;
  }

  const { heading, body } = data;
  return (
    <section className="bg-slant-right mt-24 mb-7">
      <div className="container flex flex-col lg:flex-row md:space-x-8">
        <BlockRichText
          className="flex-1 text-[30px] md:text-[50px] text-right lg:pl-24 mb-4 leading-none"
          data={heading.data.heading}
        />
        <BlockRichText
          className="flex-1 text-xl"
          data={body.data.body}
        />
      </div>
    </section>
  )
}

export default Intro
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react'
import { useRef } from 'react';
import Slider from 'react-slick/lib/slider';
import SliderNav from './slider/slider-nav';

type Props = {}

function ImageSlide({
  id,
  permalink,
  img,
  caption,
}) {
  return (
    <a
      className="p-4"
      href={permalink}
      target="_blank"
    >
      <img src={img.publicURL} alt="Logo" />;
      {/* @ts-ignore-next-line */}
      {/* <GatsbyImage image={getImage(img)} alt={caption} /> */}
    </a>
  )
}

function Instagram({}: Props) {
  const slider = useRef()
  const { t } = useTranslation()

  const { allStrapiIgPost } = useStaticQuery(graphql`
    query {
      allStrapiIgPost {
        nodes {
          id
          caption
          permalink
          publishedAt
          mediaType
          img {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 250
                height: 250
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  `)

  return (
    <section className="px-6 py-16 pb-20">
      <div className="container text-center mb-8">
        <h2 className="section-title text-center mb-2">
          {t('follow us on instagram')}
        </h2>
        <a
          href="https://www.instagram.com/advisoryapps/"
          target="_blank"
          className="text-secondary"
        >
          @advisoryapps
        </a>
      </div>
      <div className="relative">
        <Slider {...{
          slidesToShow: 5,
          lazyLoad: true,
          infinite: true,
          arrows: false,
          ref: slider,
          responsive: [{
            breakpoint: 1023,
            settings: {
              slidesToShow: 3,
            }
          }, {
            breakpoint: 639,
            settings: {
              slidesToShow: 2,
            }
          }]
        }}>
          {allStrapiIgPost.nodes.map(igPost => <ImageSlide key={igPost.id} {...igPost} />)}
        </Slider>
        <SliderNav
          className="absolute right-0 top-0 bottom-0 inline-flex items-center p-4 bg-white/30"
          direction="right"
          // @ts-ignore-next-line
          onClick={() => slider.current.slickNext()}
        />
      </div>
    </section>
  )
}

export default Instagram
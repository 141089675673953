import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react'

type Props = {}

function AchieveCollab({ data }) {
  const processedAchieveCollab = data.filter(({ file }) => !!file);
  const { t } = useTranslation()

  return (
    <section className="home-section">
      <h2 className="section-title text-center">
        {t('achievements & collaboration')}
      </h2>
      <div className="grid grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-5">
        {processedAchieveCollab.map(({ file, id }) => {
          return (
            <GatsbyImage
              key={id}
              // @ts-ignore-next-line
              image={getImage(file!.localFile as IGatsbyImageData)}
              alt={file!.alternativeText}
              objectFit="contain"
            />
          )
        })}
      </div>
    </section>
  )
}

export default AchieveCollab
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react'
import { IconLink } from '../icon-link-button';

type Props = {}

function WhatWeDo({ data }) {
  const { t } = useTranslation()

  return (
    <section className="bg-slant-left py-[200px] mb-16">
      <div className="container text-center">
        <h2 className="section-title mb-16">
          {t('what we do')}
        </h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6 gap-y-20 mb-[6rem] justify-around">
          {data.map(({
            icon,
            iconActive,
            id,
            slug,
            title
          }) => (
            <Link
              className="group inline-flex flex-col justify-center font-bold text-secondary hover:text-primary text-2xl"
              key={id}
              to={`/service/${slug}`}
            >
              <span className="inline-block mb-4">
                {/* @ts-ignore-next-line */}
                <GatsbyImage className="group-hover:hidden" image={getImage(icon.localFile)} alt={icon.alternativeText} />
                {/* @ts-ignore-next-line */}
                <GatsbyImage className="!hidden group-hover:!inline-block" image={getImage(iconActive.localFile)} alt={iconActive.alternativeText} />
              </span>
              <span>{title}</span>
            </Link>
          ))}
        </div>
        <IconLink
          to="/service/systems"
          icon={faChevronRight}
        >
          {t('learn more')}
        </IconLink>
      </div>
    </section>
  )
}

export default WhatWeDo
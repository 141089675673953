import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BannerHome from "../components/banner/home"
import Intro from "../components/home/intro"
import OurClients from "../components/home/our-clients"
import AchieveCollab from "../components/home/achieve-collab"
import GoogleMap from "../components/google-map"
import OurFlow from "../components/home/our-flow"
import WhatWeDo from "../components/home/what-we-do"
import OurPortfolio from "../components/home/our-portfolio"
import FreeConsultationForm from "../components/free-consultation-form"
import Instagram from "../components/instagram"

const IndexPage = (props) => {
  const { pageContext } = props
  const {
    strapiGlobal,
    whatWeDo,
    strapiHome,
  } = props.data

  const {
    siteDescription,
    contactDetails,
  } = strapiGlobal;

  const {
    achieveCollab,
    intro,
    ourClients,
    ourFlow,
    ourPortfolio,
  } = strapiHome;

  return (
    <Layout logoOnly>
      <Seo
        seo={{
          metaTitle: "We are Advisory Apps",
          description: siteDescription,
          lang: pageContext.strapiLangKey,
        }}
      />
      <BannerHome />
      <main>
        <Intro data={intro} />
        <OurClients data={ourClients} />
        <WhatWeDo data={whatWeDo.servicesOrder.map(({ service }) => service)} />
        <OurFlow data={ourFlow} />
        <OurPortfolio data={ourPortfolio} />
        <AchieveCollab data={achieveCollab} />
        <Instagram />
        <GoogleMap />
        <FreeConsultationForm {...{ contactDetails }} />
      </main>
    </Layout>
  )
}

export const query = graphql`
  fragment Seo on STRAPI__COMPONENT_SHARED_SEO {
    metaSocial {
      socialNetwork
      title
      description
    }
    metaTitle
    metaDescription
    keywords
    internal {
      content
    }
    metaRobots
    structuredData {
      internal {
        content
      }
    }
    metaImage {
      localFile {
        childImageSharp {
          og: resize(
            height: 630
            width: 1200
          ) {
            src
          }
          twitter: resize(
            height: 330
            width: 600
          ) {
            src
          }
        }
      }
    }
  }

  fragment Locales on LocaleConnection {
    edges {
      node {
        ns
        data
        language
      }
    }
  }

  fragment ContactDetails on STRAPI_GLOBAL {
    contactDetails {
      number {
        data {
          number
        }
      }
      address {
        data {
          address
        }
      }
      email {
        data {
          email
        }
      }
    }
  }

  fragment Icon on STRAPI__MEDIA {
    alternativeText
    localFile {
      childImageSharp {
        gatsbyImageData(
          height: 100
          placeholder: TRACED_SVG
        )
      }
    }
  }

  fragment ServicesOrder on STRAPI_GLOBAL {
    servicesOrder {
      service {
        id
        title
        slug
        body {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        icon {
          ...Icon
        }
        iconActive {
          ...Icon
        }
      }
    }
  }

  query (
    $language: String!
    $strapiLangKey: String!
  ) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      ...Locales
    }
    strapiHome(locale: {eq: $strapiLangKey}) {
      intro {
        heading {
          data {
            heading
          }
        }
        body {
          data {
            body
          }
        }
      }  
      ourClients {
        id
        file {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                backgroundColor: "white"
                transformOptions: {
                  fit: CONTAIN
                }
                width: 260
                height: 170
                placeholder: TRACED_SVG
              )
            }
          }
        }
      }
      ourFlow {
        id
        title
        richTextBody: content {
          __typename
          ... on STRAPI__COMPONENT_OUR_FLOW_OUR_FLOWContent {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
                layout: CONSTRAINED
              )
            }
          }
        }
      }
      ourPortfolio {
        ... on STRAPI_WORK {
          id
          slug
          featureImage {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
      achieveCollab {
        id
        file {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                backgroundColor: "white"
                transformOptions: {
                  fit: CONTAIN
                }
                layout: CONSTRAINED, height: 100
                placeholder: TRACED_SVG
              )
            }
          }
        }
      }
    }
    strapiGlobal(locale: {eq: $strapiLangKey}) {
      siteName
      siteDescription
      ...ContactDetails
    }
    whatWeDo: strapiGlobal(
      locale: { eq: $strapiLangKey }
    ) {
      ...ServicesOrder
    }
  }
`;

export default IndexPage

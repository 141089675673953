import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react'
import BlockRichText from '../block-rich-text';
import VerticalSeparator from './vertical-separator';

type Props = {}

const imageWrapperClasses = [
  'flex',
  'items-center',
  'basis-1/3',
  'relative',
]

function ImageContent({
  image,
  index,
  richTextBody,
  title,
  totalCount,
}) {
  // Could replace these with odd:utils
  // https://tailwindcss.com/docs/hover-focus-and-other-states
  const innerWrapperClasses = [
    'flex',
    'flex-col',
    'md:flex-row',
    'md:text-center',
    'items-center',
    'w-full',
    'space-y-6',
  ];

  const isOdd = (index + 1) % 2;
  let processedImageWrapperClasses = [''];

  if (isOdd) {
    processedImageWrapperClasses = [
      ...imageWrapperClasses,
      'mr-auto',
      'md:mr-[unset]',
    ]

    innerWrapperClasses.push(
      'text-right',
    );

  } else {
    innerWrapperClasses.push(
      'md:flex-row-reverse',
      'text-left'
    )

    processedImageWrapperClasses = [
      ...imageWrapperClasses,
      'ml-auto',
      'md:ml-[unset]',
    ]
  }

  return (
    <div
      className="px-4 sm:px-16 md:px-0 flex flex-col items-center space-y-4 mb-[100px] group"
      data-aos='fade-in'
      data-aos-once
    >
      <div className={innerWrapperClasses.join(' ')}>
        <div className={processedImageWrapperClasses.join(' ')}>
          <GatsbyImage
            className="md:!absolute group-odd:left-0 m-auto group-even:right-0"
            // @ts-ignore-next-line
            image={getImage(image.localFile)}
            alt={image.alternativeText}
          />
        </div>
        <div className="basis-1/3 space-y-4">
          <h3 className="font-bold text-[32px]">{title}</h3>
          <BlockRichText className="text-xl" data={{ richTextBody }} />
        </div>
        <div className="basic-1/3" />
      </div>
      { index + 1 < totalCount ? <VerticalSeparator {...{ isOdd: Boolean(isOdd) }} /> : ''}
    </div>
  )
}

function OurFlow({ data }) {
  const { t } = useTranslation()

  return (
    <section className="py-8">
      <h2 className="section-title text-center mb-16">
        {t('our flow')}
      </h2>
      <div className="flex flex-col">
        {data.map((flow, index) => (
          <ImageContent
            key={flow.id}
            {...flow}
            {...{
              index,
              totalCount: data.length
            }}
          />
        ))}
      </div>
    </section>
  )
}

export default OurFlow
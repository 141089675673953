import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react'

type Props = {}

function GoogleMap({}: Props) {
  const [loadMap, setLoadMap] = useState(false);
  const mapSrc = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2817.043732782965!2d101.67890661599225!3d3.1207622451135757!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4ec19ac9a429%3A0x7f1220a9d1ad79cf!2sAdvisory%20Apps%20Sdn.%20Bhd.!5e0!3m2!1sen!2smy!4v1592379982336!5m2!1sen!2smy';

  return (
    <div
      className="relative w-screen h-[200px] md:h-[330px]"
      onMouseEnter={() => setLoadMap(true)}>
      { loadMap && (
        <iframe
          className="absolute w-full h-full"
          src={mapSrc}
          allowFullScreen
          width="600"
          height="200"
        />
      )}
      <StaticImage
        className="absolute -z-[1] m-auto left-0 right-0 top-0 bottom-0"
        src="map-static.png"
        alt="AdvisoryApps office on google map"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="cover"
      />
    </div>
  )
}

export default GoogleMap
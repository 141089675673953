import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import gsap from 'gsap';
import { TweenMax } from 'gsap/gsap-core';
import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import BannerHomeImg from './home.svg';

const BannerHome = () => {
  const el = useRef();
  const { t } = useTranslation()

  return (
    <section className="h-screen max-h-[991px] min-h-[768px] mt-[-109px] relative" ref={el}>
      <div className="container h-full flex flex-col md:flex-row items-center overflow-x-hidden">
        <h1 className="relative pt-[9rem] md:pt-28 md:pt-0">
          {t('we are')}
          <br />
          <span className="text-primary font-bold">advisory</span>apps
        </h1>
        <div className="banner absolute md:static banner-home">
          <BannerHomeImg />
        </div>
      </div>
      <div className="text-center -mt-20 left-0 right-0 mx-auto">
        <p className="text-primary text-2xl lg:text-[40px]">
          {t('quality above all else')}
        </p>
        <StaticImage
          className="mt-2 mx-auto"
          src="double-arrows.png"
          alt="scroll arrows"
          placeholder="tracedSVG"
          layout="fixed"
        />
      </div>
    </section>
  )
}

export default BannerHome;
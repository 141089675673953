import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import Slider from 'react-slick'
import SliderNav from '../slider/slider-nav'
import "slick-carousel/slick/slick.css"
import { useRef } from 'react'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'
import { IconLink } from '../icon-link-button'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

function OurPortfolio({ data }) {
  const settings = {
    centerMode: true,
    centerPadding: 1,
    speed: 500,
    slidesToShow: 3,
    arrows: false,
  }

  const slider = useRef();
  const sliderNavClassName = [
    'absolute',
    'text-[70px]',
    'text-white',
    'top-0',
    'bottom-0',
    'm-auto',
    'z-10',
  ]

  // TODO - Remove when there are more content
  const portfolioArray = data.length > 1 ? data : [...Array(10).keys()].map(() => data[0]);
  const { t } = useTranslation()

  return (
    <section className="bg-slant-right py-[160px] mb-6">
      <div className="container text-center">
        <h2 className="section-title mb-16">
          {t('our portfolio')}
        </h2>
      </div>
      <div className="mb-[6rem] py-[100px] md:py-[180px] relative">
        <SliderNav className={[...sliderNavClassName, 'left-4'].join(' ')} direction="left" onClick={() => slider.current.slickPrev()} />
        <Slider key="our-portfolio-slick" ref={slider} className="slider our-flow" {...settings}>
          {portfolioArray.map(({
            featureImage,
            id,
            slug,
          }, index) => (
            <Link
              className="group inline-flex flex-col justify-center font-bold hover:text-primary text-2xl"
              key={`${id}-${index}`}
              to={`/work/${slug}`}
            >
              {/* @ts-ignore-next-line */}
              <GatsbyImage image={getImage(featureImage.localFile)} alt={featureImage.alternativeText} />
              {/* @ts-ignore-next-line */}
            </Link>
          ))}
        </Slider>
        <SliderNav className={[...sliderNavClassName, 'right-4'].join(' ')} direction="right" onClick={() => slider.current.slickNext()} />
      </div>
      <div className="container text-center">
        <IconLink
          to="/works"
          icon={faChevronRight}
        >
          {t('learn more')}
        </IconLink>
      </div>
    </section>
  )
}

export default OurPortfolio
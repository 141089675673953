import React from 'react'

type Props = {
  isOdd: boolean;
}

function VerticalSeparator({
  isOdd,
}: Props) {
  const mobileSeparatorClasses = [
    'block',
    'sm:hidden',
  ];

  if (isOdd) {
    mobileSeparatorClasses.push('-scale-x-100');
  }

  return (
    <div className="vertical-separator" data-aos="drawing" data-aos-once>
      <svg className="hidden sm:inline-block" width="11" height="106" viewBox="0 0 11 106" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="5.50002" y1="100.005" x2="4.59097" y2="0.00867583" stroke="#926A23"></line>
        <circle cx="5.5" cy="100.5" r="5.5" fill="#926A23"></circle>
      </svg>
      <svg className={mobileSeparatorClasses.join(' ')} width="121" height="147" viewBox="0 0 121 147" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M120.5 0V77.5H3.5L3.5 144" stroke="#926A23"></path>
        <circle cx="3.5" cy="143.5" r="3.5" fill="#926A23"></circle>
      </svg>
    </div>
  )
}

export default VerticalSeparator